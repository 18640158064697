import Vue from 'vue';
import Router from 'vue-router';
import store from './vuex/store.js';

Vue.use(Router);

import { AlertaEsconde } from '@/helpers/Funcoes.js';

/**
 * Layout
 */
import AdminLayout from '@/layout/Admin.vue';
import LoginLayout from '@/layout/Login.vue';

/**
 * View
 */
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import NotFound from '@/views/NotFound.vue';

/**
 * View PADRÃO
 */
import PaginaPadrao from '@/views/Padrao/PaginaPadrao.vue';
import { Cargo } from '@/views/Cargo/Cargo.js';
import { Colaborador } from '@/views/Colaborador/Colaborador.js';
import ColaboradorGrid from '@/Modulo/Colaborador/Componente/Grid.vue';
import ColaboradorForm from '@/views/Colaborador/Form.vue';
import { Departamento } from '@/views/Departamento/Departamento.js';
import { ListaRamal } from '@/views/ListaRamal/ListaRamal.js';
import { Noticia } from '@/views/Noticia/Noticia.js';
import { Permissao } from '@/views/Permissao/Permissao.js';
import { Modulo } from '@/views/Modulo/Modulo.js';
import { Sindicato } from '@/views/Sindicato/Sindicato.js';
import { TipoContratacao } from '@/views/TipoContratacao/TipoContratacao.js';
import { UnidadeEmpreendimento } from '@/views/UnidadeEmpreendimento/UnidadeEmpreendimento.js';
import { Usuario } from '@/views/Usuario/Usuario.js';
import AlocacaoCarga from '@/views/AlocacaoCarga/AlocacaoCarga.vue';
import AlocacaoCargaPainelEntrega from '@/views/AlocacaoCarga/PainelEntrega.vue';
import Romaneios from '@/views/AlocacaoCarga/Romaneios.vue';
import RomaneiosDisponiveis from '@/Modulo/AlocacaoCargaRomaneio/Componente/RomaneiosDisponiveis.vue';
import DevolucaoVazioGrid from '@/views/DevolucaoVazio/Grid.vue';
import DevolucaoVazioForm from '@/views/DevolucaoVazio/Form.vue';
import DevolucaoVazioAuditoriaProcesso from '@/views/DevolucaoVazio/AuditoriaProcesso.vue';
import { AtestadoCID } from '@/views/AtestadoCID/AtestadoCID.js';
import { AtestadoMotivo } from '@/views/AtestadoMotivo/AtestadoMotivo.js';
import UsuarioPerfil from '@/views/Usuario/UsuarioPerfil.vue';
import AvaliacaoDesempenho from '@/views/AvaliacaoDesempenho/AvaliacaoDesempenho.vue';
import AvaliacaoDesempenhoForm from '@/views/AvaliacaoDesempenho/Form.vue';
import AvaliacaoDesempenhoResposta from '@/views/AvaliacaoDesempenho/AvaliacaoDesempenhoResposta.vue';
import AvaliacaoDesempenhoRespostaForm from '@/views/AvaliacaoDesempenho/FormResposta.vue';
import AvaliacaoDesempenhoFeedbackForm from '@/views/AvaliacaoDesempenho/FormFeedback.vue';
import TrackingEmail from '@/views/TrackingEmail/TrackingEmail.vue';
import TrackingEmailForm from '@/views/TrackingEmail/Form.vue';
import ProfitLessStatement from '@/Modulo/ProfitLess/Componente/ProfitLessStatement.vue';
import ProfitLessMarkupGrid from '@/Modulo/ProfitLessMarkup/Componente/Grid.vue';
import ProfitLessMarkupForm from '@/Modulo/ProfitLessMarkup/Componente/Form.vue';
import Whatsapp from '@/Modulo/Whatsapp/Componente/Whatsapp.vue';
import FluxoCaixa from '@/Modulo/FluxoCaixa/Componente/FluxoCaixa.vue';
import AtendimentoWhatsapp from '@/Modulo/Whatsapp/Componente/Atendimento.vue';
import AtendimentoFluxoWhatsapp from '@/Modulo/Whatsapp/Componente/AtendimentoFluxo.vue';
import WhatsappAtendenteGrid from '@/Modulo/WhatsappAtendente/Componente/Grid.vue';
import WhatsappAtendenteForm from '@/Modulo/WhatsappAtendente/Componente/Form.vue';
import WhatsappMarketingGrid from '@/Modulo/WhatsappMarketing/Componente/Grid.vue';
import WhatsappMarketingForm from '@/Modulo/WhatsappMarketing/Componente/Form.vue';
import WhatsappDepartamentoGrid from '@/Modulo/WhatsappDepartamento/Componente/Grid.vue';
import WhatsappDepartamentoForm from '@/Modulo/WhatsappDepartamento/Componente/Form.vue';
import WhatsappFluxoGrid from '@/Modulo/WhatsappFluxo/Componente/Grid.vue';
import WhatsappFluxoForm from '@/Modulo/WhatsappFluxo/Componente/Form.vue';
import PainelAuditoria from '@/Modulo/Cte/Componente/PainelAuditoria.vue';
import PainelFaturamento from '@/Modulo/Faturamento/Componente/PainelFaturamento.vue';
import PainelJornadaMotorista from '@/Modulo/Monitoramento/Motorista/Componente/PainelJornadaMotorista.vue';
import PainelJornadaInicioViagem from '@/Modulo/Monitoramento/Motorista/Componente/PainelJornadaInicioViagem.vue';
import PainelJornadaParadaEspera from '@/Modulo/Monitoramento/Motorista/Componente/PainelJornadaParadaEspera.vue';
import PainelJornadaFimViagem from '@/Modulo/Monitoramento/Motorista/Componente/PainelJornadaFimViagem.vue';

import PlanoDesenvolvimentoIndividual from '@/views/PlanoDesenvolvimentoIndividual/PlanoDesenvolvimentoIndividual.vue';
import PlanoDesenvolvimentoIndividualForm from '@/views/PlanoDesenvolvimentoIndividual/Form.vue';



/**
 * Relatórios
 */
import DocumentoPronto from '@/views/Relatorio/AlocacaoCarga/DocumentoPronto.vue';

import Functionario from '@/views/Relatorio/RH/Funcionario.vue';

import OrderToDelivery from '@/views/Relatorio/ProgramacaoCarga/OrderToDelivery.vue';

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            redirect: '/login'
        },
        { name: 'page-not-found', path: '/page-not-found', component: NotFound },
        { path: '*', redirect: { name: 'page-not-found' } },
        {
            name: 'login',
            path: '/login',
            component: LoginLayout
        },
        {
            name: 'admin',
            path: '/admin',
            redirect: '/admin/dashboard',
            component: AdminLayout,
            meta: {
                autenticacao: false
            },
            children: [
                { name: 'dashboard', path: 'dashboard', component: Dashboard },
                {
                    name: 'lista-ramal',
                    path: 'lista-ramal',
                    component: PaginaPadrao,
                    props: ListaRamal,
                    meta: { breadcrumbs: 'Cadastro/Lista Ramal' }
                },
                {
                    name: 'unidade-empreendimento',
                    path: 'unidade-empreendimento',
                    component: PaginaPadrao,
                    props: UnidadeEmpreendimento
                },
                { name: 'noticia', path: 'noticia', component: PaginaPadrao, props: Noticia },
                { name: 'cargo', path: 'cargo', component: PaginaPadrao, props: Cargo },
                {
                    name: 'colaborador',
                    path: 'colaborador',
                    component: ColaboradorGrid,
                    meta: { breadcrumbs: 'Cadastro/Colaborador' }
                },
                {
                    name: 'colaborador-form',
                    path: 'colaborador/colaborador-form/:id_colaborador?',
                    component: ColaboradorForm,
                    meta: { breadcrumbs: `Cadastro/Colaborador/Form` }
                },
                { name: 'permissao', path: 'permissao', component: PaginaPadrao, props: Permissao },
                { name: 'modulo', path: 'modulo', component: PaginaPadrao, props: Modulo },
                {
                    name: 'sindicato',
                    path: 'sindicato',
                    component: PaginaPadrao,
                    props: Sindicato,
                    meta: { breadcrumbs: 'Cadastro/Sindicato' }
                },
                {
                    name: 'tipo-contratacao',
                    path: 'tipo-contratacao',
                    component: PaginaPadrao,
                    props: TipoContratacao,
                    meta: { breadcrumbs: 'Cadastro/Tipo Contratação' }
                },
                { name: 'departamento', path: 'departamento', component: PaginaPadrao, props: Departamento },
                { name: 'atestado-cid', path: 'atestado-cid', component: PaginaPadrao, props: AtestadoCID },
                { name: 'atestado-motivo', path: 'atestado-motivo', component: PaginaPadrao, props: AtestadoMotivo },
                { name: 'usuario-perfil', path: 'usuario-perfil', component: UsuarioPerfil },
                { name: 'usuario', path: 'usuario', component: PaginaPadrao, props: Usuario },
                {
                    name: 'alocacao-carga',
                    path: 'alocacao-carga/demanda',
                    component: AlocacaoCarga,
                    meta: { breadcrumbs: 'Programação de Carga/Demandas' }
                },
                {
                    name: 'alocacao-carga-painel-entrega',
                    path: 'alocacao-carga/painel-entrega',
                    component: AlocacaoCargaPainelEntrega,
                    meta: { breadcrumbs: 'Programação de Carga/Painel de Entrega' }
                },
                {
                    name: 'romaneios',
                    path: 'alocacao-carga/romaneios',
                    component: Romaneios,
                    meta: { breadcrumbs: 'Alocação de Carga/Programados' }
                },
                {
                    name: 'romaneios-disponiveis',
                    path: 'alocacao-carga/romaneios-disponiveis',
                    component: RomaneiosDisponiveis,
                    meta: { breadcrumbs: 'Alocação de Carga/Romaneios Disponíveis' }
                },
                {
                    name: 'devolucao-vazio',
                    path: 'alocacao-carga/devolucao-vazio',
                    component: DevolucaoVazioGrid,
                    meta: { breadcrumbs: 'Alocação de Carga/Devolução Vazio' }
                },
                {
                    name: 'devolucao-vazio-form',
                    path: 'alocacao-carga/devolucao-vazio-form/:id_devolucao_vazio',
                    component: DevolucaoVazioForm,
                    meta: { breadcrumbs: `Alocação de Carga/Devolução Vazio/Form` }
                },
                {
                    name: 'devolucao-vazio-auditoria-processo',
                    path: 'alocacao-carga/devolucao-vazio/auditoria-processo',
                    component: DevolucaoVazioAuditoriaProcesso,
                    meta: { breadcrumbs: `Devolução Vazio/Auditoria Processo` }
                },
                { name: 'documento-pronto', path: 'relatorio/alocacao-carga/documento-pronto', component: DocumentoPronto },
                { name: 'funcionario', path: 'relatorio/rh/funcionario', component: Functionario },
                {
                    name: 'avaliacao-desempenho',
                    path: 'rh/avaliacao-desempenho/avaliacao',
                    component: AvaliacaoDesempenho,
                    meta: { breadcrumbs: 'RH/Avaliação Desempenho/Avaliação' }
                },
                {
                    name: 'avaliacao-desempenho-form',
                    path: 'rh/avaliacao-desempenho/avaliacao/form/:id_avaliacao_desempenho?',
                    component: AvaliacaoDesempenhoForm,
                    meta: { breadcrumbs: 'RH/Avaliação Desempenho/Avaliação' }
                },
                {
                    name: 'avaliacao-desempenho-resposta',
                    path: 'rh/avaliacao-desempenho/resposta',
                    component: AvaliacaoDesempenhoResposta,
                    meta: { breadcrumbs: 'RH/Avaliação Desempenho/Resposta' }
                },
                {
                    name: 'avaliacao-desempenho-resposta-form',
                    path: 'rh/avaliacao-desempenho/avaliacao/resposta-form/:id_avaliacao_desempenho_avaliado',
                    component: AvaliacaoDesempenhoRespostaForm,
                    meta: { breadcrumbs: 'RH/Avaliação Desempenho/Respostas' }
                },
                {
                    name: 'avaliacao-desempenho-resposta-feedback',
                    path: 'rh/avaliacao-desempenho/avaliacao/resposta-feedback/:id_avaliacao_desempenho_avaliado',
                    component: AvaliacaoDesempenhoFeedbackForm,
                    meta: { breadcrumbs: 'RH/Avaliação Desempenho/Feedback' }
                },
                {
                    name: 'tracking-email',
                    path: 'cadastros/tracking-email',
                    component: TrackingEmail,
                    meta: { breadcrumbs: 'Cadastros/Tracking E-mail' }
                },
                {
                    name: 'tracking-email-form',
                    path: 'cadastros/tracking-email/form/:id_tracking_email?',
                    component: TrackingEmailForm,
                    meta: { breadcrumbs: 'Cadastros/Tracking E-mail/Form' }
                },
                {
                    name: 'plano-desenvolvimento-individual',
                    path: 'rh/plano-desenvolvimento-individual',
                    component: PlanoDesenvolvimentoIndividual,
                    meta: { breadcrumbs: 'RH/Plano Desenvolvimento Individual' }
                },
                {
                    name: 'plano-desenvolvimento-individual-form',
                    path: 'rh/plano-desenvolvimento-individual/form/:id_plano_desenvolvimento_individual',
                    component: PlanoDesenvolvimentoIndividualForm,
                    meta: { breadcrumbs: 'RH/Plano Desenvolvimento Individual/Form' }
                },
                {
                    name: 'relatorio-order-to-delivery',
                    path: 'relatorio/programacao-carga/order-to-delivery',
                    component: OrderToDelivery,
                    meta: { breadcrumbs: 'Relatório/Programação de Carga/Order to Delivery' }
                },
                {
                    name: 'profit-less',
                    path: 'financeiro/profit-less/statement',
                    component: ProfitLessStatement,
                    meta: { breadcrumbs: 'Financeiro/P&L (profit and less statement)' }
                },
                {
                    name: 'profit-less-markup-grid',
                    path: 'comercial/profit-less-markup',
                    component: ProfitLessMarkupGrid,
                    meta: { breadcrumbs: 'Comercial/Markup' }
                },
                {
                    name: 'profit-less-markup-form',
                    path: 'comercial/profit-less-markup/form/:id_profit_less_markup?',
                    component: ProfitLessMarkupForm,
                    meta: { breadcrumbs: 'Comercial/Markup' }
                },
                {
                    name: 'profit-less-markup-form-aprovacao',
                    path: 'comercial/profit-less-markup/form/:id_profit_less_markup?',
                    component: ProfitLessMarkupForm,
                    meta: { breadcrumbs: 'Comercial/Markup/aprovacao' }
                },
                {
                    name: 'alocacao-carga-whatsapp',
                    path: 'alocacao-carga/whatsapp',
                    component: Whatsapp,
                    meta: { breadcrumbs: 'Programação Caraga/Whatsapp' }
                },
                {
                    name: 'cte-painel-auditoria',
                    path: 'alocacao-carga/cte-painel-auditoria',
                    component: PainelAuditoria,
                    meta: { breadcrumbs: 'Painel Auditoria CTe' }
                },
                {
                    name: 'fluxo-caixa',
                    path: 'financeiro/fluxo-caixa',
                    component: FluxoCaixa,
                    meta: { breadcrumbs: 'Financeiro/Fluxo Caixa' }
                },
                {
                    name: 'whatsapp-atendimento-fluxo',
                    path: 'whatsapp/atendimento-fluxo',
                    component: AtendimentoFluxoWhatsapp,
                    meta: { breadcrumbs: 'Atendimento/Whatsapp Fluxo' }
                },
                {
                    name: 'whatsapp-atendimento',
                    path: 'whatsapp/atendimento',
                    component: AtendimentoWhatsapp,
                    meta: { breadcrumbs: 'Atendimento/Whatsapp Atendimento' }
                },
                {
                    name: 'whatsapp-atendente',
                    path: 'whatsapp-atendente',
                    component: WhatsappAtendenteGrid,
                    meta: { breadcrumbs: 'Whatsapp Atendente' }
                },
                {
                    name: 'whatsapp-atendente-form',
                    path: 'whatsapp-atendente/:id_whatsapp_atendente?',
                    component: WhatsappAtendenteForm,
                    meta: { breadcrumbs: 'Whatsapp Atendente' }
                },
                {
                    name: 'whatsapp-marketing',
                    path: 'whatsapp-marketing',
                    component: WhatsappMarketingGrid,
                    meta: { breadcrumbs: 'Whatsapp marketing' }
                },
                {
                    name: 'whatsapp-marketing-form',
                    path: 'whatsapp-marketing/:id_whatsapp_marketing?',
                    component: WhatsappMarketingForm,
                    meta: { breadcrumbs: 'Whatsapp Marketing' }
                },
                {
                    name: 'whatsapp-departamento',
                    path: 'whatsapp-departamento',
                    component: WhatsappDepartamentoGrid,
                    meta: { breadcrumbs: 'Whatsapp Departamento' }
                },
                {
                    name: 'whatsapp-departamento-form',
                    path: 'whatsapp-departamento/:id_whatsapp_departamento?',
                    component: WhatsappDepartamentoForm,
                    meta: { breadcrumbs: 'Whatsapp Departamento' }
                },
                {
                    name: 'whatsapp-fluxo',
                    path: 'whatsapp-fluxo',
                    component: WhatsappFluxoGrid,
                    meta: { breadcrumbs: 'Whatsapp Fluxo' }
                },
                {
                    name: 'whatsapp-fluxo-form',
                    path: 'whatsapp-fluxo/:id_whatsapp_fluxo?',
                    component: WhatsappFluxoForm,
                    meta: { breadcrumbs: 'Whatsapp Fluxo' }
                },
                {
                    name: 'painel-faturamento',
                    path: 'financeiro/painel-faturamento',
                    component: PainelFaturamento,
                    meta: { breadcrumbs: 'Painel Faturamento' }
                },
                {
                    name: 'painel-jornada-motorista',
                    path: 'monitoramento/motorista/jornada-motorista',
                    component: PainelJornadaMotorista,
                    meta: { breadcrumbs: 'Jornada Motorista' }
                },
                {
                    name: 'painel-jornada-inicio-viagem',
                    path: 'monitoramento/motorista/jornada-inicio-viagem',
                    component: PainelJornadaInicioViagem,
                    meta: { breadcrumbs: 'Jornada Motorista - Início Viagem' }
                },
                {
                    name: 'painel-jornada-parada-espera',
                    path: 'monitoramento/motorista/jornada-parada-espera',
                    component: PainelJornadaParadaEspera,
                    meta: { breadcrumbs: 'Jornada Motorista - Parada Espera' }
                },
                {
                    name: 'painel-jornada-fim-viagem',
                    path: 'monitoramento/motorista/jornada-fim-viagem',
                    component: PainelJornadaFimViagem,
                    meta: { breadcrumbs: 'Jornada Motorista - Fim Viagem' }
                },
            ]
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.autenticacao) && !store.state.estaAutenticado) {
        next({
            path: '/login'
        });
    } else {
        AlertaEsconde();

        next();
    }
});

export default router;
