<template>
    <div>
        <TituloModulo />

        <AlertaMensagem dense style="width: 100%" />

        <v-overlay :absolute="true" opacity="0.8" :value="carregando.mostraCarregando">
            <CarregandoLogoEmpresa :carregando="carregando.mostraCarregando" :mensagem="mensagemCarregando" />
        </v-overlay>

        <BarraNavegacaoBotaoV3
            v-if="!carregando.mostraCarregando && permissao"
            :botoes="botoesComPermissao(profitLessMarkupSituacao)"
        />

        <v-row v-if="!carregando.mostraCarregando && permissao">
            <v-col cols="12" class="text-right">
                <TabelaVuetify
                    v-model="datatable.itensSelecionados"
                    :cabecalho="datatable.cabecalho"
                    :itens="datatable.itens"
                    :total_registros="datatable.totalRegistros"
                    :por_pagina="datatable.por_pagina"
                    :ultima_pagina="datatable.ultima_pagina"
                    :carregando="datatable.carregando"
                    :chave_primaria="datatable.chave_primaria"
                    :ordenar_por="datatable.ordenarPor"
                    :ordenar_direcao="datatable.ordenarDirecao"
                    :mostra_selecao="false"
                    :mostra_expand="true"
                    :single_expand="true"
                    @opcoes="buscaProfitLessMarkupItens"
                    ref="tabela"
                >
                    <template v-slot:item.acao="{ item }">
                        <v-tooltip v-if="habilitaVisualizarItem(item.situacao)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="green darken-2"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="visualizaItem(item.id_profit_less_markup)"
                                >
                                    mdi-eye-check
                                </v-icon>
                            </template>
                            <span>Visualizar markup</span>
                        </v-tooltip>

                        <v-tooltip v-if="habilitaEditarItem(item.situacao)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="orange darken-1"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editaItem(item.id_profit_less_markup)"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip v-if="habilitaEditarAprovacao(item.situacao)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="green darken-1"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="aprovaItem(item.id_profit_less_markup)"
                                >
                                    mdi-check-decagram
                                </v-icon>
                            </template>
                            <span>Verificar aprovação</span>
                        </v-tooltip>

                        <v-tooltip v-if="habilitaImprimir(item.situacao)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="blue darken-1"
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="imprimirItem(item)"
                                >
                                    mdi-printer
                                </v-icon>
                            </template>
                            <span>Imprimir</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.situacao="{ item }">
                        <v-chip class="ma-2" :color="selecionaCor(item.situacao)" text-color="white">
                            <v-icon left>
                                mdi-alert-circle-outline
                            </v-icon>
                            {{ item.situacao }}
                        </v-chip>
                    </template>

                    <template v-slot:item.total_aprovado="{ item }">
                        <v-chip v-if="item.total_aguardando_aprovacao" class="ma-2" color="cyan" label text-color="white">
                            <v-icon left>
                                mdi-account-circle-outline
                            </v-icon>
                            {{ item.total_aprovado }} / {{ item.total_aguardando_aprovacao }}
                        </v-chip>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td v-if="item.aprovacao.length" :colspan="headers.length">
                            <v-card elevation="5" class="ma-5" width="400">
                                <v-card-title>
                                    APROVADOR
                                </v-card-title>

                                <v-card-text>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        NOME
                                                    </th>
                                                    <th class="text-left">
                                                        APROVADO ?
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(itemAprovacao, index) in item.aprovacao"
                                                    :key="`item-aprovacao-${index}`"
                                                >
                                                    <td class="text-left">{{ itemAprovacao.nome_completo }}</td>
                                                    <td class="text-left">{{ descricaoSimNao(itemAprovacao.aprovado) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </td>
                    </template>
                </TabelaVuetify>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-snackbar
                v-model="notificacao.mostra"
                :multi-line="true"
                :color="`${notificacao.tipo} accent-4`"
                elevation="10"
                timeout="3000"
                bottom
                text
            >
                <span v-html="notificacao.mensagem"></span>

                <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="notificacao.mostra = false">
                        Fechar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { API_URL } from '@/config/configs';
import ApiService from '@/services/ApiService.js';
import { Alerta } from '@/helpers/Funcoes.js';

import SituacaoForm from '@/Modulo/ProfitLessMarkup/Dominio/Enum/SituacaoForm.js';
import SituacaoEnum from '@/Modulo/ProfitLessMarkup/Dominio/Enum/SituacaoEnum.js';
import { descricao as simNaoDescricao } from '@/Enum/Shared/SimNaoEnum.js';

import TabelaAcao from '@/Modulo/Compartilhado/Tabela/Dominio/Servico/Acao';

import AlertaMensagem from '@/components/Default/AlertaMensagem.vue';
import CarregandoLogoEmpresa from '@/components/Default/CarregandoLogoEmpresa.vue';
import TituloModulo from '@/components/Default/TituloModulo.vue';
import BarraNavegacaoBotaoV3 from '@/components/Default/BarraNavegacaoBotaoV3.vue';
import TabelaVuetify from '@/components/Default/TabelaVuetify.vue';

export default {
    name: 'Grid',
    mixins: [TabelaAcao],
    components: {
        AlertaMensagem,
        BarraNavegacaoBotaoV3,
        CarregandoLogoEmpresa,
        TituloModulo,
        TabelaVuetify
    },
    async mounted() {
        await this.carregaDados();

        if (this.$route.params.mensagem) {
            Alerta([this.$route.params.mensagem], 'success');
        }
    },
    computed: {
        ...mapGetters(['botoesComPermissao']),
        ...mapState(['carregando'])
    },
    watch: {},
    methods: {
        ...mapActions(['montaNavegacao', 'mostraCarregando']),
        async carregaDados() {
            try {
                this.mostraCarregando(true);

                //await this.dadosPaginaInicial();

                await this.carregaPermissoes();

                this.permissao = true;
            } catch (erro) {
                Alerta([erro.message], 'error');
            } finally {
                this.mostraCarregando(false);
            }
        },
        async dadosPaginaInicial() {
            try {
                await ApiService({
                    method: 'get',
                    url: `${API_URL.erp.profitlessmarkup.dadosPaginaInicial}`,
                    data: {},
                    mostraCarregando: false
                })
                    .then(async resposta => {})
                    .catch(erro => {
                        throw new Error(erro.message);
                    });
            } catch (erro) {
                Alerta([erro.message], 'error');
            }
        },
        async carregaPermissoes() {
            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.permissao}`,
                data: {},
                mostraCarregando: false
            })
                .then(async resposta => {
                    const permissoes = resposta.data.data.atributos;

                    this.profitLessMarkupSituacao = SituacaoForm.GRID;

                    await this.montaNavegacao({ botoes: this.botoes, permissoes });
                })
                .catch(error => {
                    throw new Error(error);
                });
        },
        async buscaProfitLessMarkupItens() {
            this.datatable.carregando = true;

            const query = this.$refs.tabela.queryFiltros();

            await ApiService({
                method: 'get',
                url: `${API_URL.erp.profitlessmarkup.datatable}/?${query}`,
                data: {},
                mostraCarregando: false
            })
                .then(resposta => {
                    this.datatable.itens = resposta.data.conteudo.datatable.itens;

                    this.datatable.totalRegistros = resposta.data.conteudo.datatable.total;
                    this.datatable.ultima_pagina = resposta.data.conteudo.datatable.ultima_pagina;
                    this.datatable.carregando = false;
                })
                .catch(erro => {
                    Alerta([erro.message], 'error');
                });
        },
        novoItem() {
            this.$router.push({
                name: 'profit-less-markup-form'
            });
        },
        visualizaItem(id_profit_less_markup) {
            this.$router.push({
                name: 'profit-less-markup-form',
                params: { id_profit_less_markup: id_profit_less_markup }
            });
        },
        editaItem(id_profit_less_markup) {
            this.$router.push({
                name: 'profit-less-markup-form',
                params: { id_profit_less_markup: id_profit_less_markup, url: 'reprovado' }
            });
        },
        aprovaItem(id_profit_less_markup) {
            this.$router.push({
                name: 'profit-less-markup-form-aprovacao',
                params: { id_profit_less_markup: id_profit_less_markup, url: 'aprovacao' }
            });
        },
        async imprimirItem(item) {
            try {
                if (!item.id_profit_less_markup) {
                    Alerta(['Selecione um registro no grid para imprimir.'], 'error');

                    return;
                }

                this.mostraCarregando(true);

                this.mensagemCarregando = 'Preparando impressão...';

                await ApiService({
                    method: 'GET',
                    url: `${API_URL.erp.profitlessmarkup.imprimir}/${item.id_profit_less_markup}`,
                    responseType: 'blob',
                    mostraCarregando: false
                })
                    .then(resposta => {
                        const blob = new Blob([resposta.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url);
                    })
                    .catch(erro => {
                        this.notificacao = {
                            mensagem: erro.message,
                            tipo: 'error',
                            mostra: true
                        };
                    });
            } catch (erro) {
                this.mostraCarregando(false);

                this.notificacao = {
                    mensagem: erro.message,
                    tipo: 'error',
                    mostra: true
                };
            } finally {
                this.mostraCarregando(false);
            }
        },
        habilitaVisualizarItem(situacaoDescricao) {
            if (
                SituacaoEnum.APROVADO == situacaoDescricao.toUpperCase() ||
                SituacaoEnum.REPROVADO == situacaoDescricao.toUpperCase()
            ) {
                return true;
            }

            return false;
        },
        habilitaEditarItem(situacaoDescricao) {
            if (SituacaoEnum.AGUARDANDO_AJUSTE == situacaoDescricao.toUpperCase()) {
                return true;
            }

            return false;
        },
        habilitaEditarAprovacao(situacaoDescricao) {
            if (SituacaoEnum.APROVACAO == situacaoDescricao.toUpperCase()) {
                return true;
            }

            return false;
        },
        habilitaImprimir(situacaoDescricao) {
            if (SituacaoEnum.APROVADO == situacaoDescricao.toUpperCase()) {
                return true;
            }

            return false;
        },
        selecionaCor(situacao) {
            if (SituacaoEnum.ABERTO == situacao.toUpperCase()) {
                return 'green darken-1';
            }

            if (SituacaoEnum.APROVACAO == situacao.toUpperCase()) {
                return 'yellow darken-3';
            }

            if (SituacaoEnum.AGUARDANDO_AJUSTE == situacao.toUpperCase()) {
                return 'yellow darken-3';
            }

            if (SituacaoEnum.APROVADO == situacao.toUpperCase()) {
                return 'green darken-1';
            }

            if (SituacaoEnum.REPROVADO == situacao.toUpperCase()) {
                return 'red darken-1';
            }

            return 'grey darken-1';
        },
        descricaoSimNao(aprovado) {
            return simNaoDescricao(aprovado);
        }
    },
    data() {
        return {
            permissao: false,
            permissoes: [],
            botoes: [
                {
                    id_permissao: 2,
                    nome: 'Novo',
                    acao: 'novoItem',
                    icone: 'mdi-newspaper-variant-multiple',
                    cor: 'green lighten-1',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'buscaProfitLessMarkupItens',
                    icone: 'mdi-reload',
                    cor: 'grey darken-5',
                    form: [SituacaoForm.GRID]
                },
                {
                    id_permissao: 6,
                    nome: '',
                    acao: 'limpaFiltro',
                    icone: 'mdi-filter-multiple',
                    cor: 'red darken-5',
                    form: [SituacaoForm.GRID]
                }
            ],
            profitLessMarkupSituacao: null,
            mensagemCarregando: 'Buscando, aguarde...',
            notificacao: {
                mostra: false,
                mensagem: '',
                tipo: 'success'
            },
            datatable: {
                carregando: false,
                mensagemCarregando: 'Buscando, aguarde...',
                chave_primaria: 'id_profit_less_markup',
                itens: [],
                totalRegistros: 0,
                ultima_pagina: 0,
                pagina_atual: 1,
                por_pagina: 50,
                ordenarPor: 'id_profit_less_markup',
                ordenarDirecao: true,
                opcoes: {},
                itensSelecionados: [],
                cabecalho: [
                    {
                        text: 'AÇÃO',
                        value: 'acao',
                        align: 'start',
                        width: '150',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'ID',
                        value: 'id_profit_less_markup',
                        width: '150'
                    },
                    {
                        text: 'Situação',
                        value: 'situacao',
                        width: '150',
                        type: 'select',
                        filterData: [
                            {
                                value: 1,
                                label: 'Aberto'
                            },
                            {
                                value: 2,
                                label: 'Aprovação'
                            },
                            {
                                value: 3,
                                label: 'APROVADO'
                            },
                            {
                                value: 4,
                                label: 'Reprovado'
                            }
                        ]
                    },
                    {
                        text: 'Aprovadores',
                        value: 'total_aprovado',
                        width: '150',
                        sortable: false,
                        showFilter: false
                    },
                    {
                        text: 'Razão Social',
                        value: 'razao_social',
                        width: '250'
                    },
                    {
                        text: 'Tipo Operação',
                        value: 'tipo_operacao',
                        width: '180'
                    },
                    {
                        text: 'GM R$',
                        value: 'margem_bruta_valor',
                        width: '150'
                    },
                    {
                        text: 'GM %',
                        value: 'margem_bruta_percentual',
                        width: '150'
                    },
                    {
                        text: 'GP R$',
                        value: 'lucro_bruto_valor',
                        width: '150'
                    },
                    {
                        text: 'GP %',
                        value: 'lucro_bruto_percentual',
                        width: '150'
                    },
                    {
                        text: 'LL R$',
                        value: 'lucro_liquido_valor',
                        width: '150'
                    },
                    {
                        text: 'LL %',
                        value: 'lucro_liquido_percentual',
                        width: '150'
                    },
                    {
                        text: 'Margem de Lucro %',
                        value: 'margem_lucro_percentual',
                        width: '200'
                    },
                    {
                        text: 'Usuário Criação',
                        value: 'usuario_criacao',
                        width: '180'
                    },
                    {
                        text: 'Data Criação',
                        value: 'data_criacao',
                        width: '180'
                    },
                    {
                        text: 'Usuário Última Alteração',
                        value: 'usuario_ultima_alteracao',
                        width: '230'
                    },
                    {
                        text: 'Data Última Alteração',
                        value: 'data_ultima_alteracao',
                        width: '230'
                    }
                ]
            },
            form: {
                profit_less: {}
            }
        };
    }
};
</script>
