export const API_URL_BASE = process.env.VUE_APP_URL_BASE;

export const API_URL = {
    erp: {
        autenticacao: {
            entra: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/autenticacao/entra`,
            renovaToken: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/autenticacao/renovaToken`,
            sai: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/autenticacao/sai`,
            validaToken: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/autenticacao/validaToken`
        },
        autenticacaoLdap: {
            listaTipoCombo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/autenticacao-ldap/lista-tipo-combo`
        },
        alocacaocarga: {
            listaCarga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/lista-carga`,
            motoristaSelecaoCombo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/motorista/selecao-combo`,
            listaTipoColetaCombo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/coleta-combo`,
            desvincularCarga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/desvincular-carga`,
            vincularCarga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/vincular-carga`,
            painelEntrega: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga/painel-entrega`
        },
        alocacaocargaromaneio: {
            novo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio`,
            romaneios: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio/romaneios`,
            romaneiosDisponiveis: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio/romaneios-disponiveis`,
            imprimir: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio/imprimir`,
            whatsappRomaneioDisponivel:
                `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio/` +
                '${id_alocacao_carga_romaneio}/whatsapp-romaneio-disponivel',
            whatsappRomaneio: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/alocacao-carga-romaneio/whatsapp`,
            whatsappConversaMensagens:
                process.env.VUE_APP_URL_PROJETO_NOME +
                '/V1/alocacao-carga-romaneio/whatsapp/${id_whatsapp_numero_atendimento}/conversa/${id_whatsapp_conversa}/mensagens',
            whatsappEnviaMensagem:
                process.env.VUE_APP_URL_PROJETO_NOME +
                '/V1/alocacao-carga-romaneio/whatsapp/${id_whatsapp_numero_atendimento}/mensagem',
            whatsappConversaVisualizada:
                process.env.VUE_APP_URL_PROJETO_NOME +
                '/V1/alocacao-carga-romaneio/whatsapp/conversa/${id_whatsapp_conversa}/visualizada'
        },
        avaliacaodesempenho: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/datatable`,
            datatableResposta: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/datatable-resposta`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/dadosPaginaInicial`,
            dadosPaginaInicialResposta: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/dados-pagina-inicial-resposta`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho`,
            buscaSituacaoAvaliacao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/listaSituacaoAvaliacaoCombo`,
            buscaColaborador: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/listaColaboradorCombo`,
            autoCompletaAvaliados: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/autoCompletaAvaliados`,
            autoCompletaCampo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/auto-completa-campo`,
            inicia: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/inicia`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho`,
            salvaResposta: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/responde-avaliacao`,
            buscaAvaliacaoAvaliado: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/busca-avaliacao-avaliado`,
            buscaAvaliacaoFeedback: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/busca-avaliacao-feedback`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/avaliacao-desempenho/permissao`
        },
        cargo: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/cargo/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/cargo/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo`,
            linhaTempo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cargo/linha-tempo/ultimo-registro`
        },
        colaborador: {
            buscaParceiroPorCnpjCpf: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/buscaParceiroPorCnpjCpf`,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/colaborador/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/colaborador',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador`,
            autoCompletaCampo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/auto-completa-campo`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/colaborador/permissao`
        },
        departamento: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/departamento/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/departamento/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/departamento`
        },
        dashboard: {
            avaliacaoDesempenhoAguardandoFinalizarPorSetor: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/avaliacao-desempenho/aguardando-finalizar-setor`,
            avaliacaoDesempenhoEmAndamento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/avaliacao-desempenho/em-andamento`,
            perfil: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/perfil`,
            orderToDeliveryAno: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/programacao-carga/order-to-delivery/ano`,
            orderToDeliveryAnoPorUnidadeEmpreedimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/programacao-carga/order-to-delivery/ano/unidade-empreedimento`,
            demanda: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/dashboard/programacao-carga/demanda`
        },
        filial: {
            lista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/filial/lista`
        },
        listaramal: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/listaramal/${id_registro}',
            listaPorIdForm: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/listaramal/${id_registro}/form',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/listaramal`
        },
        modulo: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/modulo/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/modulo/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/modulo`
        },
        noticia: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/noticia/${id_registro}',
            listaPorIdForm: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/noticia/${id_registro}/form',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia`,
            linhaTempo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/noticia/linha-tempo/ultimo-registro`
        },
        parceiro: {
            id_modulo: 5,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/parceiro/${id_registro}',
            listaPorIdForm: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/parceiro/${id_registro}/form',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro`,
            autoCompletaParceiroRodopar: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/parceiro/busca-rodopar`
        },
        permissao: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/permissao/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/permissao/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/permissao`
        },
        sindicato: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/sindicato/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/sindicato/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/sindicato`
        },
        tipocontratacao: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/tipo-contratacao/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/tipo-contratacao/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tipo-contratacao`
        },
        unidadeempreendimento: {
            id_modulo: 4,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento/dadosPaginaInicial`,
            lista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento/lista`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/unidadeempreendimento/${id_registro}',
            listaPorIdForm: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/unidadeempreendimento/${id_registro}/form',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/unidadeempreendimento`
        },
        usuario: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/usuario/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/usuario/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario`,
            usuarioPerfil: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario/$id_usuario/perfil`,
            usuarioPerfilDashboardAtualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/usuario/perfil-dashboard`
        },
        atestadocid: {
            autoCompleta: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid/autoCompletaCampo`,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/atestadocid/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/atestadocid/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadocid`
        },
        atestadomotivo: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo/dadosPaginaInicial`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/atestadomotivo/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/atestadomotivo/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/atestadomotivo`
        },
        motorista: {
            completaCampo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/motorista/completa-campo`,
            lista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/motorista/lista`,
            painelMotorista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/motorista/painel-motorista`,
            painelJornadaMotorista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/motorista/painel-jornada`
        },
        veiculo: {
            autoCompleta: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo/autoCompletaCampo`,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo/datatable`,
            dadosPaginaFormNovo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo/dadosPaginaFormNovo`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo/dadosPaginaInicial`,
            lista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo/lista`,
            listaPorId: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/veiculo/${id_registro}',
            busca: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/veiculo/${id_registro}',
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo`,
            atualiza: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/veiculo`
        },
        programacaocarga: {
            mudaStatus: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/programacao-carga/muda-status`,
            listaStatusCombo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/programacao-carga/status-combo`
        },
        planodesenvolvimentoindividual: {
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual`,
            enviaDocumentos: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/envia-documentos`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/dadosPaginaInicial`,
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/datatable`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual`,
            conclui: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/conclui`,
            aprova: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/aprova`,
            downloadAnexo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/download-anexo`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/permissao`,
            imprimir: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/plano-desenvolvimento-individual/imprimir`
        },
        trackingemail: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tracking-email/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tracking-email/dadosPaginaInicial`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/tracking-email`
        },
        devolucaovazio: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/dados-pagina-inicial`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/permissao`,
            situacao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/situacao`,
            buscaParceiroRodopar: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/parceiro`,
            buscaMotorista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/motorista`,
            buscaPlaca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/placa`,
            downloadAnexo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/download-anexo`,
            apagaDocumento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/apaga-documento`,
            auditoriaProcesso: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/auditoria-processo`,
            auditarProcesso: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/devolucao-vazio/{id_devolucao_vazio}/auditar-processo`
        },
        imagem: {
            avatarColaborador: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/imagem/avatar-colaborador`
        },
        cte: {
            notaFiscalOcorrencia: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cte/$numero_cte/nota_fiscal/ocorrencia`,
            painelAuditoria: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cte/painel-auditoria`,
            confirmaAuditoria: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cte/painel-auditoria/confirma-auditoria`,
            salvaObservacaoAuditoria: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cte/painel-auditoria/observacao`,
            datatablePainelAuditoria: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/cte/datatable/painel-auditoria`
        },
        faturamento: {
            painelFaturamento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/faturamento/painel-auditoria`,
            liberaFaturamento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/faturamento/painel-auditoria/libera-faturamento`
        },
        grupoCliente: {
            autoCompletaRodopar: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/grupo-cliente/auto-completa-rodopar`
        },
        profitLess: {
            statement: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less/statement`
        },
        profitlessmarkup: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/dados-pagina-inicial`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup`,
            reprova: process.env.VUE_APP_URL_PROJETO_NOME + '/V1/profit-less-markup/${id_registro}/reprovar',
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/permissao`,
            situacao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/situacao`,
            buscaParceiroRodopar: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/parceiro`,
            buscaEnderecoQualp: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/endereco`,
            calcularRotaQualp: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/calcular-rota`,
            imprimir: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/profit-less-markup/imprimir`
        },
        whatsapp: {
            conversaLista: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/conversa`,
            //conversaMensagem: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/conversa/mensagens`,
            fluxoConversaMensagens:
                process.env.VUE_APP_URL_PROJETO_NOME +
                '/V1/whatsapp/atendimento/${id_whatsapp_numero_atendimento}/fluxo-conversa/${id_whatsapp_fluxo_conversa}/mensagens',
            conversaMensagens:
                process.env.VUE_APP_URL_PROJETO_NOME +
                '/V1/whatsapp/atendimento/${id_whatsapp_numero_atendimento}/conversa/${id_whatsapp_conversa}/mensagens',
            enviaMensagem: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/mensagem/conversa`,
            conversaVisualizada: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/conversa/visualizada`,
            buscaAtendentes: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/atendentes`,
            contatoAtendimentoFluxo: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/contato-atendimento/fluxo/numero-atendimento`,
            contatoAtendimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/contato-atendimento/numero_atendimento`,
            chamadoAtendimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/chamado-atendimento/numero-atendimento`,
            transferenciaAtendimento:
                `${process.env.VUE_APP_URL_PROJETO_NOME}` +
                '/V1/whatsapp/fluxo-conversa/{id_whatsapp_fluxo_conversa}/transferencia-atendimento/{id_whatsapp_atendente}',
            finalizaAtendimento:
                `${process.env.VUE_APP_URL_PROJETO_NOME}` +
                '/V1/whatsapp/fluxo-conversa/{id_whatsapp_fluxo_conversa}/finaliza-atendimento',
            iniciaAtendimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/atendente-fluxo-conversa`,
            mensagemAtendimento:
                `${process.env.VUE_APP_URL_PROJETO_NOME}` +
                '/V1/whatsapp/fluxo-conversa/{id_whatsapp_fluxo_conversa}/atendimento/{id_whatsapp_numero_atendimento}/mensagem',
            perfilAtendente: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/perfil-atendente`,
            numeroAtendimentoDisponivel: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/numero-atendimento-disponivel`,
            adicionaConversa: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp/numero-atendimento/{id_whatsapp_numero_atendimento}/contato`
        },
        whatsappatendente: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/dados-pagina-inicial`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/permissao`,
            situacao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/situacao`,
            buscaUsuario: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/usuario`,
            buscaDepartamento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-atendente/departamento`
        },
        whatsappmarketing: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing/dados-pagina-inicial`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing/permissao`,
            situacao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing/situacao`,
            comboWhatsappNumeroAtendimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-marketing/combo/numero-atendimento`
        },
        whatsappdepartamento: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento/dados-pagina-inicial`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-departamento/permissao`
        },
        whatsappfluxo: {
            datatable: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo/datatable`,
            dadosPaginaInicial: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo/dados-pagina-inicial`,
            apaga: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo`,
            busca: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo`,
            salva: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo`,
            permissao: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo/permissao`,
            buscaWhatsappNumeroAtendimento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo/numero-atendimento`,
            buscaWhatsappDepartamento: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/whatsapp-fluxo/departamentos`
        },
        fluxoCaixa: {
            extrato: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/fluxo-caixa/extrato`
        },
        relatorio: {
            programacaoCarga: {
                orderToDelivery: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/relatorio/programacao-carga/order-to-delivery`,
                orderToDeliveryAno: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/relatorio/programacao-carga/order-to-delivery-ano`,
                demanda: `${process.env.VUE_APP_URL_PROJETO_NOME}/V1/relatorio/programacao-carga/demanda`
            }
        }
    }
};
