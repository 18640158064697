const IMPACTO = 1;
const G3 = 2;
const POTENCIAL = 3;
const STO_ANTONIO = 4;
const SOBRE_RODAS_SSZ = 5;
const TRANSPARENCY = 6;
const TACT_CARGO = 7;
const EM_VIAGEM = 8;
const EADI = 9;
const EXPORTACAO = 10;
const TRANSMARTYNA = 11;
const SOBRE_RODAS_TBE = 12;
const CGM = 13;
const TSA = 14;
const GARPE = 15;

export function descricao() {
    return [
        { label: 'GARPE', value: GARPE},
        { label: 'TSA', value: TSA},
        { label: 'CGM', value: CGM},
        { label: 'EADI', value: EADI },
        { label: 'EM VIAGEM', value: EM_VIAGEM },
        { label: 'EXPORTAÇÃO', value: EXPORTACAO },
        { label: 'G3', value: G3 },
        { label: 'IMPACTO', value: IMPACTO },
        { label: 'POTENCIAL', value: POTENCIAL },
        { label: 'STO ANTONIO', value: STO_ANTONIO },
        { label: 'SOBRE RODAS SSZ', value: SOBRE_RODAS_SSZ },
        { label: 'SOBRE RODAS TBE', value: SOBRE_RODAS_TBE },
        { label: 'TACT CARGO', value: TACT_CARGO },
        { label: 'TRANSPARENCY', value: TRANSPARENCY },
        { label: 'TRANSMARTYNA', value: TRANSMARTYNA },
    ];
}
